import { useEffect } from 'react';
import { GetStaticPaths, GetStaticProps } from 'next';
import NotFound from 'src/NotFound';
import Layout from 'src/Layout';
import {
  RenderingType,
  ComponentPropsContext,
  EditingComponentPlaceholder,
  StaticPath,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { handleEditorFastRefresh } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { SitecorePageProps } from 'lib/page-props';
import { sitecorePagePropsFactory } from 'lib/page-props-factory';
import { componentBuilder } from 'temp/componentBuilder';
import { sitemapFetcher } from 'lib/sitemap-fetcher';
import HandleMockError, { getMockError } from 'helpers/ErrorHandling/HandleMockError';
import { FastSitecoreContext } from 'helpers/FastSitecoreContext/FastSitecoreContext';
import config from 'temp/config';

const SitecorePage = ({
  notFound,
  componentProps,
  layoutData,
  headLinks,
  mockError,
}: SitecorePageProps): JSX.Element => {
  useEffect(() => {
    // Since Sitecore editors do not support Fast Refresh, need to refresh editor chromes after Fast Refresh finished
    handleEditorFastRefresh();
  }, []);

  if (notFound || !layoutData.sitecore.route) {
    // Shouldn't hit this (as long as 'notFound' is being returned below), but just to be safe
    return <NotFound />;
  }

  if (mockError) {
    return <HandleMockError {...mockError} />;
  }

  const isEditing = layoutData.sitecore.context.pageEditing;
  const isComponentRendering =
    layoutData.sitecore.context.renderingType === RenderingType.Component;
  return (
    <ComponentPropsContext value={componentProps}>
      <FastSitecoreContext
        componentFactory={componentBuilder.getComponentFactory({ isEditing })}
        layoutData={layoutData}
      >
        {/*
          Sitecore Pages supports component rendering to avoid refreshing the entire page during component editing.
          If you are using Experience Editor only, this logic can be removed, Layout can be left.
        */}
        {isComponentRendering ? (
          <EditingComponentPlaceholder rendering={layoutData.sitecore.route} />
        ) : (
          <Layout layoutData={layoutData} headLinks={headLinks} />
        )}
      </FastSitecoreContext>
    </ComponentPropsContext>
  );
};

// This function gets called at build and export time to determine
// pages for SSG ("paths", as tokenized array).
export const getStaticPaths: GetStaticPaths = async (context) => {
  // Fallback, along with revalidate in getStaticProps (below),
  // enables Incremental Static Regeneration. This allows us to
  // leave certain (or all) paths empty if desired and static pages
  // will be generated on request (development mode in this example).
  // Alternatively, the entire sitemap could be pre-rendered
  // ahead of time (non-development mode in this example).
  // See https://nextjs.org/docs/basic-features/data-fetching/incremental-static-regeneration

  let paths: StaticPath[] = [];
  let fallback: boolean | 'blocking' = 'blocking';

  context.locales = context.locales?.filter((x) => x?.toLocaleLowerCase() !== 'default');

  if (process.env.NODE_ENV !== 'development' && !process.env.DISABLE_SSG_FETCH) {
    try {
      // Note: Next.js runs export in production mode
      paths = await sitemapFetcher.fetch(context);

      // attempt to exclude 404 and 500 pages from building since it is breaking the xm cloud deployment's internal editing host.
      /*
      const newPaths: StaticPath[] = [];
      paths.forEach(function (p) {
        let addPath = true;
        p.params.path.forEach(function (ppp) {
          if (ppp === '_404' || ppp === '_500') {
            addPath = false;
          }
        });
        if (addPath) {
          newPaths.push(p);
        }
      });
      paths = newPaths;
      */
      if (config.sitecoreSiteName.toLocaleLowerCase() === 'corporate') {
        paths = paths.filter(
          (x) =>
            x.params.path.join('/') !== '_site_Corporate/impact/transparency/ingredients-we-use'
        );
        // paths.forEach(function (p) {
        //   console.log('These are the paths', p.params.path.join('/'), p.locale);
        // });
      }
    } catch (error) {
      console.log('Error occurred while fetching static paths');
      console.log(error);
    }

    fallback = process.env.EXPORT_MODE ? false : fallback;
  }

  return {
    paths,
    fallback,
  };
};

// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation (or fallback) is enabled and a new request comes in.
export const getStaticProps: GetStaticProps = async (context) => {
  const props = await sitecorePagePropsFactory.create(context);

  const mockError = getMockError(context);
  if (mockError?.throwStaticPropsError) {
    throw Error('mock error getStaticProps');
  }

  if (mockError?.throwRenderError) {
    throw Error('mock error throwRenderError');
  }

  if (mockError?.throwClientSideError) {
    throw Error('mock error throwClientSideError');
  }
  props.mockError = mockError;

  return {
    props,
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 5 seconds
    revalidate: !!process.env.ISR_REVALIDATE_TIMER
      ? parseInt(process.env.ISR_REVALIDATE_TIMER)
      : 60, // In seconds
    notFound: props.notFound, // Returns custom 404 page with a status code of 404 when true
  };
};

export default SitecorePage;
